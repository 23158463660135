import "./App.css"
import React, { useState } from "react";
import Banner from "./Banner";
import Nav from "./Nav";
import Row from "./Row";
import requests from "./requests";
import Header from "./Header";
import Footer from "./Footer";




function App() {
  
  return (
    <>
      {/* <Nav /> */}
      <Header/>
      <Banner />
      {/* <h1>Welcome to My Netflix Movies Page</h1> */}
      <Row
        title="netflix orginal"
        fetchUrl={requests.fetchNetflixOrginals}
        largerow
       
      />
      <Row
        title=" trending now"
        fetchUrl={requests.fetchTrending}
       
      />
      <Row
        title=" Top Rated Movies"
        fetchUrl={requests.fetchTopRatedMovies}
      
      />
      <Row
        title=" Action Movies"
        fetchUrl={requests.fetchActionMovies}
   
      />
      <Row
        title=" Comedy Movies"
        fetchUrl={requests.fetchComedyMovies}
 
      />
      <Row
        title=" Horror Movies"
        fetchUrl={requests.fetchHorrorMovies}
       
      />
      <Row
        title=" Romantic Movies"
        fetchUrl={requests.fetchRomanticMovies}
    
      />
      <Row
        title=" Documentary Movies"
        fetchUrl={requests.fetchDocumentaryMovies}
      />
      <Row
        title=" TV Shows"
        fetchUrl={requests.fetchTvShows}
      />
      <Footer/>
    </>
  );
}

export default App;
