import React, { useEffect, useState } from "react";
import "./Row.css";
import axios from "./axios";
import YouTube from "react-youtube";
import movieTrailer from "movie-trailer";
// import requests from "./requests";

const baseURL = "https://image.tmdb.org/t/p/original/";

function Row({ title, fetchUrl, largerow }) {
  const [movies, setMovies] = useState([]);
  const [trailerUrl, setTrailerUrl] = useState("");

  useEffect(() => {
    async function fetchMoviesData() {
      const request = await axios.get(fetchUrl);
      setMovies(request.data.results);
      return request;
    }
    fetchMoviesData();
  }, [fetchUrl]);
console.log(movies);
  const opts = {
    height: "380",
    width: "80%",
    playerVars: {
      autoplay: 1,
    },
  };

  const handleClick = (movie) => {
    if (trailerUrl) {
      setTrailerUrl(""); // Close the trailer if it's already open
    } else {
      movieTrailer(movie?.title || movie?.name || movie?.original_name)
        .then((url) => {
          const urlParams = new URLSearchParams(new URL(url).search);
          setTrailerUrl(urlParams.get("v")); // Set the video ID for the YouTube component
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <div className="row">
      <h1>{title}</h1>
      <div className="row__posters">
        {movies?.map((eachmovie) => (
          <img 
            // style={{ height: "450px" }}
            onClick={() => handleClick(eachmovie)} // Pass the correct movie object
            className={`row__poster ${largerow && "large_Row_Poster"} images`}
            src={`${baseURL}${
              largerow ? eachmovie.poster_path : eachmovie.backdrop_path
            }`}
            alt={eachmovie.name || eachmovie.original_title}
          />
        ))}
      </div>
      <div style={{ padding: "40px" }}>
        {trailerUrl && <YouTube videoId={trailerUrl} opts={opts} />}
      </div>
    </div>
  );
}

export default Row;

