import React, { useState, useEffect } from "react";
import "./Header.css";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AccountBoxIcon from "@mui/icons-material/AccountBox";

function Header() {
  const [bgColor, setBgColor] = useState("transparent");

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 100) {
        setBgColor("black");
      } else {
        setBgColor("transparent");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="header" style={{ backgroundColor: bgColor }}>
      <img
        className="header__logo"
        src="https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg"
        alt="Netflix Logo"
      />
      <div className="header__nav">
        <a href="#">Home</a>
        <a href="#">TV Shows</a>
        <a href="#">Movies</a>
        <a href="#">Latest</a>
        <a href="#">My List</a>
        <a href="#">Browse by Language</a>
      </div>
      <div className="header__icons">
        <SearchIcon className="header__icon" />
        <NotificationsIcon className="header__icon" />
        <AccountBoxIcon className="header__icon" />
        <div className="header__profile">
          <ArrowDropDownIcon className="header__icon" />
        </div>
      </div>
    </div>
  );
}

export default Header;
