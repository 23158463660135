import React from "react";
import "./Footer.css";
import { Container, Grid, Link, Typography } from "@mui/material";
import { Facebook, Instagram, Twitter, YouTube } from "@mui/icons-material";

function Footer() {
  return (
    <div className="footer">
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item xs={12} className="footer__social">
            <Facebook className="footer__icon" />
            <Instagram className="footer__icon" />
            <Twitter className="footer__icon" />
            <YouTube className="footer__icon" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <ul>
              <li>
                <Link href="#" variant="body2" color="inherit">
                  Audio Description
                </Link>
              </li>
              <li>
                <Link href="#" variant="body2" color="inherit">
                  Gift Cards
                </Link>
              </li>
              <li>
                <Link href="#" variant="body2" color="inherit">
                  Investor Relations
                </Link>
              </li>
              <li>
                <Link href="#" variant="body2" color="inherit">
                  Netflix Shop
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={3}>
            <ul>
              <li>
                <Link href="#" variant="body2" color="inherit">
                  Privacy
                </Link>
              </li>
              <li>
                <Link href="#" variant="body2" color="inherit">
                  Cookie Preferences
                </Link>
              </li>
              <li>
                <Link href="#" variant="body2" color="inherit">
                  Contact Us
                </Link>
              </li>
              <li>
                <Link href="#" variant="body2" color="inherit">
                  Ad Choices
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={3}>
            <ul>
              <li>
                <Link href="#" variant="body2" color="inherit">
                  Help Center
                </Link>
              </li>
              <li>
                <Link href="#" variant="body2" color="inherit">
                  Media Center
                </Link>
              </li>
              <li>
                <Link href="#" variant="body2" color="inherit">
                  Jobs
                </Link>
              </li>
              <li>
                <Link href="#" variant="body2" color="inherit">
                  Terms of Use
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={3}>
            <ul>
              <li>
                <Link href="#" variant="body2" color="inherit">
                  Legal Notices
                </Link>
              </li>
              <li>
                <Link href="#" variant="body2" color="inherit">
                  Corporate Information
                </Link>
              </li>
              <li>
                <Link href="#" variant="body2" color="inherit">
                  Don't Sell My Personal Information
                </Link>
              </li>
              <li>
                <Link href="#" variant="body2" color="inherit">
                  Service Code
                </Link>
              </li>
            </ul>
          </Grid>
        </Grid>
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          style={{ marginTop: "20px" }}
        >
          © 2024 Netflix, Inc.
        </Typography>
        <p>
          {" "}
          <center>Developed by Gebrye Mulatu@2024</center>
        </p>
      </Container>
    </div>
  );
}

export default Footer;
